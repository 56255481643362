
<template>
  <main class="main">
    <section class="section is-top is-padding-horizontal-safe is-wide-lg">
      <div class="is-overlay"></div>
    </section>
    <section class="section is-about is-padding-horizontal-safe is-wide-lg">
      <div class="inner is-padding-horizontal-md is-space">
        <h2 class="text is-primary is-normal is-center is-lg is-fablet-xl is-tablet-xxl">誰も知らない自分だけの場所。</h2>
        <p class="text is-line-height-lg is-center is-sm">
          <span class="text is-inline-block">旅旅は誰も知らない自分だけの場所を見つけるをコンセプトに、</span>
          <span class="text is-inline-block">日本全国の場所をオープンデータを活用して、</span>
          <span class="text is-inline-block">機械学習やディープラーニングの手法を持ち寄り、</span>
          <span class="text is-inline-block">まだ誰も知らないその場所を見つける試みです。</span>
          <span class="text is-inline-block">そして、解析されたデータの場所に赴き、</span>
          <span class="text is-inline-block">データの整合性を検証するために旅をして新たなる発見をしていきます。</span>
        </p>
      </div>
    </section>
    <section class="section is-feature is-padding-horizontal-safe is-wide-lg">
      <div id="adSpace" class="inner is-padding-horizontal-md is-space text is-center">
      </div>
    </section>
    <section class="section is-feature is-padding-horizontal-safe is-wide-lg">
      <div class="inner is-padding-horizontal-md is-space">
        <div class="is-middle is-center is-gap-xl">

          <div id="blogCard" class="grid is-gap-md">
            <div id="blogCard" class="column is-4">

            </div>
            <div class="column is-4">
              <div class="card is-radius-md is-bg-light is-floating">
                <div class="box is-padding-md">children</div>
              </div>
            </div>
            <div class="column is-4">
              <div class="card is-radius-md is-bg-light is-floating">
                <div class="box is-padding-md">children</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import dayjs from 'dayjs';

export default{
  data() {
    return {
      title: '旅旅 | 誰も知らない自分だけの場所',
      description: '旅旅は誰も知らない自分だけの場所を見つけるをコンセプトに、日本全国の場所をオープンデータを活用して、機械学習やディープラーニングの手法を持ち寄り、まだ誰も知らないその場所を見つける試みです。',
      url: 'https://tabi2.lol/',
      thumbnail: 'https://tabi2.lol/thumbnail.png',
      rssReader: 'https://livlog.xyz/webapi/rss?url=',
      blogRss: 'https://note.com/livlog_llc/m/m5e2bc5e2a64e/rss',
      adSpace:`
      <!-- 旅旅の間の広告 -->
      <ins class="adsbygoogle"
          style="display:inline-block;width:728px;height:90px"
          data-ad-client="ca-pub-3143681225966869"
          data-ad-slot="2295347153"></ins>
      <script>
          (adsbygoogle = window.adsbygoogle || []).push({});
      <`+`/script>
      `,
    }
  },
  async mounted() {

    $('#adSpace').html(this.adSpace);

    $('title').text(this.title);
    $('[name="title"]').attr('content', this.title);
    $('[name="description"]').attr('content', this.description);
    $('[property="og:title"]').attr('content', this.title);
    $('[property="og:description"]').attr('content', this.description);
    $('[property="og:image"]').attr('content', this.thumbnail);
    $('[property="og:url"]').attr('content', location.href);
    $('[name="twitter:url"]').attr('content', location.href);
    $('[name="twitter:title"]').attr('content', this.title);
    $('[name="twitter:description"]').attr('content', this.description);
    $('[rel="canonical"]').attr('href', location.href);
    $('body,html').scrollTop(0);

    // RSSの取得
    const doc = await fetch(this.rssReader + this.blogRss)
    .then(this.checkStatus)
    .then(this.parseXML)
    .catch((error) => {
      console.log('request failed', error);
    });
    let rss = doc.documentElement.getElementsByTagName('item');
    //HTMLタグの作成
    $('#blogCard').html('');
    const width = $(window).width();
    let columnSplit = 'is-4';
    if (width < 600) {
      columnSplit = 'is-12';
    }
    for (let i = 0; i < rss.length; i++) {
      if (i >= 24) {
        break;
      }

      //RSSから取得したタイトルとリンク情報を格納
      let title = rss[i].getElementsByTagName("title")[0].textContent;
      let link  = rss[i].getElementsByTagName("link")[0].textContent;
      let thumbnail = rss[i].getElementsByTagName("media:thumbnail")[0].textContent;
      let description = rss[i].getElementsByTagName("description")[0].textContent;
      let pubDate = rss[i].getElementsByTagName("pubDate")[0].textContent;
      let creatorName = rss[i].getElementsByTagName("note:creatorName")[0].textContent;
      description = $(description).text();
      $('#blogCard').append(`
      <div id="blogCard${i}" class="column ${columnSplit}">
        <div class="blog-card">
          <div class="details">
            <div class="date">
              <span class="day">${dayjs(pubDate).format('YYYY/MM/DD')}</span>
            </div>
            <ul class="meta">
            </ul>
          </div>
          <div class="description">
            <div class="content">
              <span class="author">${creatorName}</span>
              <h1><a href="${link}" target="_blank">${title}</a></h1>
              <p class="text">${description}</p>
              <a href="${link}" class="button" target="_blank">Read more</a>
            </div>
          </div>
        </div> 
      </div>       
      `);

      $(`#blogCard${i}`).find('.blog-card').css('background',`url('${thumbnail}') center/cover no-repeat`);
    }
  },
  methods: {
    checkStatus: function(res) {
      if (res.status >= 200 && res.status < 300) {
        return res;
      } else {
        const error = new Error(res.statusText);
        error.response = res;
        throw error;
      }
    },
    parseXML: function(response) {
      return response.text().then((stringContainingXMLSource) => {
        const parser = new DOMParser();
        return parser.parseFromString(stringContainingXMLSource, "text/xml");
      })
    },
  },
}
</script>
