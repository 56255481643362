import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/springwater",
    name: "Springwater",
    component: () =>
      import(/* webpackChunkName: "Springwater" */ "../views/Springwater.vue"),
  },
  {
    path: "/springwater/:id",
    name: "Springwater_id",
    component: () =>
      import(
        /* webpackChunkName: "Springwater_id" */ "../views/Springwater.vue"
      ),
    props: true,
  },
  {
    path: "/hikyoekirank",
    name: "Hikyoekirank",
    component: () =>
      import(
        /* webpackChunkName: "Hikyoekirank" */ "../views/Hikyoekirank.vue"
      ),
  },
  {
    path: "/hikyoekirank/:id",
    name: "Hikyoekirank_id",
    component: () =>
      import(
        /* webpackChunkName: "Hikyoekirank_id" */ "../views/Hikyoekirank.vue"
      ),
    props: true,
  },
  {
    path: "/plateau-aqicn",
    name: "PlateauAqicn",
    component: () =>
      import(
        /* webpackChunkName: "PlateauAqicn" */ "../views/PlateauAqicn.vue"
      ),
  },
  {
    path: "/big-tree",
    name: "BigTree",
    component: () =>
      import(/* webpackChunkName: "BigTree" */ "../views/BigTree.vue"),
  },
  {
    path: "/big-tree/:id",
    name: "BigTree_id",
    component: () =>
      import(/* webpackChunkName: "BigTree_id" */ "../views/BigTree.vue"),
    props: true,
  },
  {
    path: "/onsen",
    name: "Onsen",
    component: () =>
      import(/* webpackChunkName: "Onsen" */ "../views/Onsen.vue"),
  },
  {
    path: "/onsen/:id",
    name: "Onsen_id",
    component: () =>
      import(/* webpackChunkName: "Onsen_id" */ "../views/Onsen.vue"),
    props: true,
  },
  {
    path: "/mountain",
    name: "Mountain",
    component: () =>
      import(/* webpackChunkName: "Mountain" */ "../views/Mountain.vue"),
  },
  {
    path: "/mountain/:id",
    name: "Mountain_id",
    component: () =>
      import(/* webpackChunkName: "Mountain_id" */ "../views/Mountain.vue"),
    props: true,
  },
  {
    path: "/japanese-ceramics",
    name: "JapaneseCeramics",
    component: () =>
      import(
        /* webpackChunkName: "JapaneseCeramics" */ "../views/JapaneseCeramics.vue"
      ),
    props: true,
  },
  {
    path: "/japanese-ceramics/:id",
    name: "JapaneseCeramics_id",
    component: () =>
      import(
        /* webpackChunkName: "JapaneseCeramics_id" */ "../views/JapaneseCeramics.vue"
      ),
    props: true,
  },
  {
    path: "/three-prefecture-border",
    name: "ThreePrefectureBorder",
    component: () =>
      import(
        /* webpackChunkName: "ThreePrefectureBorder" */ "../views/ThreePrefectureBorder.vue"
      ),
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
